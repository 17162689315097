document.body.addEventListener("htmx:beforeRequest", (event) => {
    if (event.detail.boosted || event.detail.requestConfig.headers['HX-Boosted']) {
        NProgress.start();
    }
});

document.body.addEventListener("htmx:afterRequest", (event) => {
    if (event.detail.boosted || event.detail.requestConfig.headers['HX-Boosted']) {
        NProgress.done();
        pageInit();
        dReady();
        beaverLoad();
        accountManagerReady()
    }
});

document.body.addEventListener("htmx:afterSwap", (event) => {
    // For tabs, when they are ajax'd on a post, we need to refresh to fix the style.
    // jQuery UI tabs seems to start adding classes to everything like ul and li tags,
    // but just doesn't add them to the div.
    // Also, it seems weird on the search page doing multiple searches will break.
    // We will update these with proper hx-get and hx-post, this is temporary.
    // Yes, I know this sucks. No, You can't reduce the time any lower
    if (event.detail.boosted || event.detail.requestConfig.headers['HX-Boosted']) {
        if ($('.tabs').length > 0) {
            setTimeout(function () {
                $('.tabs').tabs("destroy")
                $('.tabs').tabs()
            }, 25);
        }
    }
});

document.body.addEventListener("htmx:beforeHistorySave", () => {
    NProgress.remove();
    // Before we save, remove all chosen containers since they'll get regenerated
    document.querySelectorAll('.chosen-container').forEach(function (item) {
        item.remove();
    })
});

document.body.addEventListener('htmx:historyRestore', () => {
    pageInit();
    dReady();
    beaverLoad();
    $("select").chosen({ width: "100%" });

    // On restore remove the expanded action. we can't do this on beforeSave save since it breaks actions when you go to them. I think this is a timing issue.
    document.querySelectorAll('tr.expanded').forEach(function (item) {
        item.classList.remove('expanded');
        item.nextElementSibling.remove();
    })
})

document.body.addEventListener('clear-history', () => {
    localStorage.removeItem("htmx-history-cache");
})

document.addEventListener('htmx:beforeSwap', (event) => {
    // If this is a boosted request, we need to make sure that we always swap the big <main> #container
    // By doing this, the nested small HTMX requests will still be performed correctly and not collapse with the main HX Boost
    if (event.detail.boosted || event.detail.requestConfig.headers['HX-Boosted']) {
        if (event.detail.shouldSwap) {
            event.detail.target = document.getElementById('container');
            event.detail.swapOverride = 'outerHTML';
        }
    }
})

document.addEventListener('htmx:responseError', (event) => {
    let responseData = '';

    if (event.detail.customEvent !== undefined) {
        responseData = event.detail.responseText;
    } else {
        const contentType = event.detail.xhr.getResponseHeader("Content-Type");
        if (!contentType.includes('application/json')) {
            return;
        }

        responseData = event.detail.xhr.responseText;
    }

    if (responseData === undefined || responseData === '') {
        return;
    }

    const parsedResponse = JSON.parse(responseData);

    if (parsedResponse.message !== undefined && parsedResponse.message !== '') {
        toastr.error(parsedResponse.message);
    }

    if (parsedResponse.errors !== undefined) {
        if (parsedResponse.errors instanceof Array) {
            parsedResponse.errors.forEach((errorMessage) => toastr.error(errorMessage));

            return;
        }

        if (parsedResponse.errors instanceof Object) {
            for (let objectKey in parsedResponse.errors) {
                toastr.error(parsedResponse.errors[objectKey]);
            }

            return;
        }

        toastr.error(parsedResponse.errors);
    }
});

document.addEventListener("htmx:confirm", function (e) {
    if (! e.detail.elt.hasAttribute("hx-confirm")) {
        return;
    }

    e.preventDefault();

    swal({
        title: e.detail.elt.getAttribute("hx-confirm") ?? "Perform Action?",
        html: e.detail.elt.getAttribute("hx-confirm-question") ?? "Are you sure you want to perform this action?",
        type: e.detail.elt.getAttribute("hx-confirm-type") ?? "warning",
        showCancelButton: true,
        focusConfirm: true,
        confirmButtonText: e.detail.elt.getAttribute("hx-confirm-button-text") ?? "Confirm",
        confirmButtonAriaLabel: e.detail.elt.getAttribute("hx-confirm-button-text") ?? "Confirm",
        cancelButtonText: 'Cancel',
        cancelButtonAriaLabel: 'Cancel'
    }).then(function(result) {
        if (result.value) {
            e.detail.issueRequest(true);
        }
    });
});

document.addEventListener("htmx:beforeSwap", function (e) {
    if (e.detail.shouldSwap) {
        let successMessage = e.detail.requestConfig.elt.getAttribute("hx-success-message") ?? "";
        if (successMessage !== "" && successMessage !== undefined) {
            toastr.success(successMessage);
        }
    }
});
